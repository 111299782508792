define("portal-user-onboarding/components/modal-onboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validationErrors: [],
    serverError: null,
    revealPassword: false,
    showValidationErrors: false,

    didInsertElement() {
      this._super(...arguments);
      $("body .modals").remove();
      $(".ui.tiny.modal").modal({
        duration: 120,
        closable: false,
        centered: false
      }).modal("show");
    },

    actions: {
      togglePasswordReveal() {
        let status = this.get("revealPassword");
        this.set("revealPassword", !status);
      },
      hide() {
        $(".ui.tiny.modal").modal({
          duration: 30
        }).modal("hide");
      },

      onboardUser() {
        // clear out errors
        this.set("validationErrors", []);
        this.set("serverError", null);

        // validation
        let onboardedUser = this.get("model.onboardedUser");
        onboardedUser.validate().then(({ validations }) => {
          if (validations.get("isValid") === false) {
            this.set("showValidationErrors", true);
            let validationErrors = [];
            validations.forEach(validation => {
              // show clear text password if caught
              if (validation.attribute === "password" || validation.attribute === "confirmPassword") {
                this.set("revealPassword", true);
              }

              let message = validation.get("message");
              if (message !== undefined) {
                validationErrors.push(validation.get("message"));
              }
            });
            this.set("validationErrors", validationErrors);
          } else {
            this.set("showValidationErrors", false);
            // onboard user
            this.get("onOnboardUser")().then(() => {
              $(".ui.tiny.modal").modal({
                duration: 30
              }).modal("hide");
            }).catch(error => {
              // server error
              if (error.errors.error !== undefined) {
                let errorObject = error.errors.error;
                let message = errorObject.detail;
                this.set("serverError", message);
              } else {
                // 500 error
                if (error.errors[0] !== undefined) {
                  let status = error.errors[0].status;
                  let title = error.errors[0].title;
                  let message = `${status} ${title}`;
                  this.set("serverError", message);
                }
              }
            });
          }
        });
      },

      updateUsername() {
        let emailAddress = this.get("model.onboardedUser.preferredEmailAddress");
        this.set("model.onboardedUser.username", emailAddress);
      }
    }
  });
});