define("portal-user-onboarding/adapters/onboarded-user", ["exports", "portal-user-onboarding/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function () {
      return "onboarding";
    },

    urlForCreateRecord(modelName, snapshot) {
      let url = this._super(...arguments);
      let onboardingCode = snapshot.adapterOptions.onboarding_code;
      return url.replace(`onboarding`, `onboarding/onboard/${onboardingCode}`);
    }
  });
});