define("portal-user-onboarding/models/public-client-config-url", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    privacyPolicyUrl: _emberData.default.attr("string"),
    termsOfUseUrl: _emberData.default.attr("string"),
    electronicRecordsUrl: _emberData.default.attr("string")
  });
});