define("portal-user-onboarding/serializers/onboarded-user", ["exports", "portal-user-onboarding/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // temporary
      // onboarding returns an empty payload
      if (requestType === "createRecord") {
        payload["onboarded_users"] = [{ id: "1" }];
      }
      return this._super(...arguments);
    }
  });
});