define("portal-user-onboarding/models/public-client-config-asset", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    companyLogoUrl: _emberData.default.attr("string"),
    faviconUrl: _emberData.default.attr("string"),
    headerLogoUrl: _emberData.default.attr("string"),
    lightBackgroundLogoUrl: _emberData.default.attr("string")
  });
});