define("portal-user-onboarding/adapters/reset-password", ["exports", "portal-user-onboarding/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: "v1/auth/user",
    pathForType: function () {
      return "password";
    },

    urlForCreateRecord(modelName, snapshot) {
      let url = this._super(...arguments);
      return url.replace("password", "password/reset");
    }
  });
});