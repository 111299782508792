define("portal-user-onboarding/models/onboarded-user", ["exports", "ember-data", "ember-cp-validations"], function (exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const Validations = (0, _emberCpValidations.buildValidations)({
    preferredEmailAddress: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Email Address can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      type: "email"
    })],

    password: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Password can't be empty"
    }), (0, _emberCpValidations.validator)("length", {
      min: 8,
      message: "Password must be at least 8 characters"
    })],

    confirmPassword: [(0, _emberCpValidations.validator)("confirmation", {
      on: "password",
      message: "'Password' and 'Confirm Password' don't match"
    })],

    acceptTermsOfUse: (0, _emberCpValidations.validator)("is-terms-accepted", true)
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    username: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    confirmPassword: _emberData.default.attr("string"),
    preferredEmailAddress: _emberData.default.attr("string"),
    preferredMobilePhoneNumber: _emberData.default.attr("string"),
    acceptTermsOfUse: _emberData.default.attr("boolean", { defalutValue: false }),
    acceptElectronicRecord: _emberData.default.attr("boolean", { defaultValue: false })
  });
});