define("portal-user-onboarding/routes/namespace/reset-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        clientConfig: this.modelFor("namespace"),
        client: this.store.peekRecord("public-client", this.modelFor("namespace").get("clientId")),
        resetPassword: this.store.createRecord("reset-password"),
        resetCode: params.reset_code
      });
    },

    actions: {
      resetPassword() {
        let resetPassword = this.modelFor(this.routeName).resetPassword;

        let token = this.modelFor(this.routeName).resetCode;
        resetPassword.setProperties({
          token: token
        });

        return resetPassword.save();
      }
    }
  });
});