define("portal-user-onboarding/models/public-client", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    applicationName: _emberData.default.attr("string"),
    buildfireAppId: _emberData.default.attr("string"),
    clientConfigId: _emberData.default.attr("string"),
    name: _emberData.default.attr("string")
  });
});