define("portal-user-onboarding/adapters/application", ["exports", "ember-data", "portal-user-onboarding/config/environment"], function (exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    namespace: _environment.default.API_NAMESPACE,
    host: _environment.default.API_HOST,

    handleResponse: function (status, headers, payload) {
      if (status === 400 || status === 404) {
        return new _emberData.default.AdapterError(payload);
      }
      return this._super(...arguments);
    },

    headers: Ember.computed('session.authToken', function () {

      let headerList = {};
      headerList["X-Client-Namespace"] = window.location.pathname.split("/")[1];

      return headerList;
    })
  });
});