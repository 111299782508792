define("portal-user-onboarding/adapters/public-client-config", ["exports", "portal-user-onboarding/adapters/application", "portal-user-onboarding/config/environment"], function (exports, _application, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: _environment.default.PUBLIC_API_NAMESPACE,
    pathForType: function () {
      return "config";
    },

    urlForFindRecord(id, modelName, snapshot) {
      let url = this._super(...arguments);
      return url.replace(`/${id}`, ``);
    }
  });
});