define("portal-user-onboarding/serializers/reset-password", ["exports", "portal-user-onboarding/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeIntoHash: function (hash, type, record, options) {
      /*
      converts :
      {
        reset_password: {
          new_password: x,
          token: y
        }
      }
       to:
      {
        new_password: x,
        token: y
      }
      */

      Ember.assign(hash, this.serialize(record, options));
    }
  });
});