define("portal-user-onboarding/routes/namespace/invite", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        clientConfig: this.modelFor("namespace"),
        client: this.store.peekRecord("public-client", this.modelFor("namespace").get("clientId")),
        inviteCode: params.invite_code
      });
    },

    actions: {
      lookupUser() {
        let inviteCode = this.modelFor(this.routeName).inviteCode;

        let query = {
          onboarding_code: inviteCode
        };

        return this.store.queryRecord("onboarding-user", query).then(onboardingUser => {
          let emailAddress = onboardingUser.get("emailAddress");
          let onboardingCode = onboardingUser.get("onboardingCode");

          this.store.createRecord("onboarded-user", {
            preferredEmailAddress: emailAddress,
            username: emailAddress
          });

          return this.transitionTo("namespace.onboard", onboardingCode);
        });
      }
    }
  });
});