define("portal-user-onboarding/routes/namespace/lookup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        clientConfig: this.modelFor("namespace"),
        onboardingUser: this.store.createRecord("onboarding-user"),
        client: this.store.peekRecord("public-client", this.modelFor("namespace").get("clientId"))
      });
    },

    actions: {
      willTransition: function () {
        let onboardingUser = this.modelFor(this.routeName).onboardingUser;
        if (onboardingUser.get("isNew") === true) {
          onboardingUser.deleteRecord();
        }
      },

      lookupUser() {
        let onboardingUser = this.modelFor(this.routeName).onboardingUser;

        let query = {
          last_name: onboardingUser.get("lastName"),
          date_of_birth: onboardingUser.get("dateOfBirth"),
          source_id: onboardingUser.get("sourceId")
        };

        return this.store.queryRecord("onboarding-user", query).then(onboardingUser => {
          let emailAddress = onboardingUser.get("emailAddress");
          let onboardingCode = onboardingUser.get("onboardingCode");

          this.store.createRecord("onboarded-user", {
            preferredEmailAddress: emailAddress,
            username: emailAddress
          });

          return this.transitionTo("namespace.onboard", onboardingCode);
        });
      }
    }
  });
});