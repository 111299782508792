define("portal-user-onboarding/models/onboarding-user", ["exports", "ember-data", "ember-cp-validations"], function (exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Validations = (0, _emberCpValidations.buildValidations)({
    lastName: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Last name can't be empty"
    })],

    sourceId: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Employee ID can't be empty"
    })],

    dateOfBirth: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Date of Birth can't be empty"
    }), (0, _emberCpValidations.validator)("date", {
      format: "YYYY-MM-DD",
      message: "Date of Birth is not valid"
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    sourceId: _emberData.default.attr("string"),
    dateOfBirth: _emberData.default.attr("string"),
    emailAddress: _emberData.default.attr("string"),
    mobilePhoneNumber: _emberData.default.attr("string"),
    onboardingCode: _emberData.default.attr("string")
  });
});