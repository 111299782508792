define("portal-user-onboarding/models/public-client-config-buildfire", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    enabled: _emberData.default.attr("boolean"),
    appId: _emberData.default.attr("string")
  });
});