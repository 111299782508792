define("portal-user-onboarding/routes/namespace", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      return this.store.findAll("public-client-config").then(clientConfigs => {
        return clientConfigs.get("firstObject");
      });
    }
  });
});