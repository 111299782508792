define("portal-user-onboarding/validators/is-terms-accepted", ["exports", "ember-cp-validations/validators/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const IsTermsAccepted = _base.default.extend({
    store: Ember.inject.service(),
    validate(value, options, model, attribute) {
      let config = this.get("store").peekAll("public-client-config");
      if (config.get("firstObject").get("buildfire").get("enabled") === true) {
        return true;
      }

      if (JSON.parse(JSON.stringify(model)).acceptTermsOfUse === false) {
        return "You must accept the Terms Of Use";
      } else {
        return true;
      }
    }
  });

  exports.default = IsTermsAccepted;
});