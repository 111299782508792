define("portal-user-onboarding/components/modal-lookup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOther: true,
    isIOS: false,
    isAndroid: false,
    validationErrors: [],
    serverError: null,
    showValidationErrors: false,

    didInsertElement() {
      this._super(...arguments);

      // initialize dropdown
      $(".ui.fluid.selection.dropdown").dropdown();

      // detect user agents
      let userAgent = this.get("userAgent");
      if (userAgent.get("browser.isChrome") === true || userAgent.get("browser.isEdge") === true || userAgent.get("browser.isFirefox") === true || userAgent.get("browser.isIE") === true || userAgent.get("browser.isSafari") === true) {
        this.set("isIOS", false);
        this.set("isAndroid", false);
        this.set("isOther", true);
      }

      if (userAgent.get("os.isIOS") === true) {
        this.set("isIOS", true);
        this.set("isAndroid", false);
        this.set("isOther", false);
      }

      if (userAgent.get("os.isAndroid") === true) {
        this.set("isIOS", false);
        this.set("isAndroid", true);
        this.set("isOther", false);
      }

      // show modal
      $("body .modals").remove();
      $(".ui.tiny.modal").modal({
        duration: 120,
        closable: false,
        centered: false
      }).modal("show");

      // testing only
      // this.set("model.onboardingUser.lastName", "Pigera");
      // this.set("model.onboardingUser.sourceId", "FMqHqFrkDq");

      // favicon
      let faviconUrl = this.get("model.clientConfig.assets.faviconUrl");
      if (faviconUrl) {
        let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = faviconUrl;
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      // set dob default
      $(".month-dropdown").dropdown("set selected", "01");
      $(".day-dropdown").dropdown("set selected", "01");
      $(".year-dropdown").dropdown("set selected", "1968");

      $("input[name=dateOfBirth]").val("1968-01-01");
    },

    actions: {
      lookupUser() {
        // clear out errors
        this.set("validationErrors", []);
        this.set("validationErrors", false);
        this.set("serverError", null);

        // date of birth: ios / android
        if (this.get("isIOS") === true) {
          let dateOfBirth = $("input[name=dateOfBirth]")[0].value;
          this.set("model.onboardingUser.dateOfBirth", dateOfBirth);
        }
        if (this.get("isOther") === true || this.get("isAndroid") === true) {
          let day = $("input[name=day]")[0].value;
          let month = $("input[name=month]")[0].value;
          let year = $("input[name=year]")[0].value;

          let dateOfBirth = `${year}-${month}-${day}`;
          this.set("model.onboardingUser.dateOfBirth", dateOfBirth);
        }

        // testing only
        // this.set("model.onboardingUser.dateOfBirth", "1988-10-05");

        // validation
        let onboardingUser = this.get("model.onboardingUser");
        onboardingUser.validate().then(({ validations }) => {
          if (validations.get("isValid") === false) {
            this.set("showValidationErrors", true);
            let validationErrors = [];
            validations.forEach(validation => {
              let message = validation.get("message");
              if (message !== undefined) {
                validationErrors.push(validation.get("message"));
              }
            });
            this.set("validationErrors", validationErrors);
          } else {
            // lookup
            this.set("showValidationErrors", false);
            this.get("onLookupUser")().then(() => {
              $(".ui.tiny.modal").modal({
                duration: 30
              }).modal("hide");
            }).catch(error => {
              // server error
              if (error.errors.error !== undefined) {
                let errorObject = error.errors.error;
                let message = errorObject.detail;
                this.set("serverError", message);
              } else {
                // 500 error
                if (error.errors[0] !== undefined) {
                  let status = error.errors[0].status;
                  let title = error.errors[0].title;
                  let message = `${status} ${title}`;
                  this.set("serverError", message);
                }
              }
            });
          }
        });
      }
    }
  });
});