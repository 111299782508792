define("portal-user-onboarding/serializers/application", ["exports", "ember-data", "ember-inflector"], function (exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute: function (attr) {
      return Ember.String.underscore(attr);
    },

    payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName);
    },

    keyForRelationship: function (attr, relationship) {
      let underscoredAttribute = Ember.String.underscore(attr);
      if (relationship === "belongsTo") {
        return underscoredAttribute + "_id";
      } else {
        return (0, _emberInflector.singularize)(underscoredAttribute) + "_ids";
      }
    }

    // serializeIntoHash: function(hash, type, record, options) {
    //   debugger;
    //   Ember.assign(hash, this.serialize(record, options));
    // }
  });
});