define("portal-user-onboarding/routes/namespace/eula", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        clientConfig: this.modelFor("namespace"),
        onboardingUser: this.store.peekAll("onboarding-user").get("firstObject"),
        onboardedUser: this.store.peekAll("onboarded-user").get("firstObject"),
        client: this.store.peekRecord("public-client", this.modelFor("namespace").get("clientId"))
      });
    },

    actions: {}
  });
});