define("portal-user-onboarding/components/modal-invite", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOther: true,
    isIOS: false,
    isAndroid: false,
    displayMessage: null,
    displayType: "",

    didInsertElement() {
      this._super(...arguments);

      // initialize dropdown
      $(".ui.fluid.selection.dropdown").dropdown();

      // detect user agents
      let userAgent = this.get("userAgent");
      if (userAgent.get("browser.isChrome") === true || userAgent.get("browser.isEdge") === true || userAgent.get("browser.isFirefox") === true || userAgent.get("browser.isIE") === true || userAgent.get("browser.isSafari") === true) {
        this.set("isIOS", false);
        this.set("isAndroid", false);
        this.set("isOther", true);
      }

      if (userAgent.get("os.isIOS") === true) {
        this.set("isIOS", true);
        this.set("isAndroid", false);
        this.set("isOther", false);
      }

      if (userAgent.get("os.isAndroid") === true) {
        this.set("isIOS", false);
        this.set("isAndroid", true);
        this.set("isOther", false);
      }

      // show modal
      $("body .modals").remove();
      $(".ui.tiny.modal").modal({
        duration: 120,
        closable: false,
        centered: false
      }).modal("show");

      // favicon
      let faviconUrl = this.get("model.clientConfig.assets.faviconUrl");
      if (faviconUrl) {
        let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = faviconUrl;
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      // fetch user
      this.set("displayMessage", null);
      this.get("onLookupUser")().then(() => {
        $(".ui.tiny.modal").modal({
          duration: 30
        }).modal("hide");
      }).catch(error => {
        this.handleError(error);
      });
    },

    processError(message) {
      if (message === "500 - Failed to locate onboarding record") {
        this.set("displayMessage", "Sorry, looks like your invite link has expired");

        this.set("displayMessageType", "warning");
        return;
      }

      if (message === "500 - You have already completed onboarding. Please login.") {
        this.set("displayMessage", "Hello! Looks like you completed onboarding. Please login");
        this.set("displayMessageType", "positive");
        return;
      }

      if (message === "500 - Encountered internal error onboarding user. Please try again.") {
        this.set("displayMessage", "Oops! Something went wrong :-(");
        this.set("displayMessageType", "warning");
        return;
      }
    },

    handleError(error) {
      if (error.errors) {
        let status = error.errors.status;
        if (error.errors.detail !== undefined) {
          // validation errors
          let list = error.errors.detail.split(";");
          if (list.length > 0) {
            list.forEach(e => {
              this.processError(message);
            });
          }
        } else {
          // name in use error
          if (error.errors.error !== undefined) {
            let code = error.errors.error.code;
            let detail = error.errors.error.detail.trim();
            let status = error.errors.error.status;
            let message = `${status} - ${detail}`;
            this.processError(message);
          } else {
            // 500 error
            if (error.errors[0] !== undefined) {
              let status = error.errors[0].status;
              let title = error.errors[0].title;
              let message = `${status} - ${title}`;
              this.processError(message);
            }
          }
        }
      }
    },

    actions: {}
  });
});