define("portal-user-onboarding/components/modal-reset-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    revealPassword: false,
    serverError: null,
    successMessage: null,

    didInsertElement() {
      this._super(...arguments);
    },

    actions: {
      togglePasswordReveal() {
        let status = this.get("revealPassword");
        this.set("revealPassword", !status);
      },

      resetPassword() {
        // clear out errors
        this.set("validationErrors", []);
        this.set("serverError", null);

        // validation
        let resetPassword = this.get("model.resetPassword");
        resetPassword.validate().then(({ validations }) => {
          if (validations.get("isValid") === false) {
            let validationErrors = [];
            validations.forEach(validation => {
              // show clear text password if caught
              if (validation.attribute === "newPassword") {
                this.set("revealPassword", true);
              }

              let message = validation.get("message");
              if (message !== undefined) {
                validationErrors.push(validation.get("message"));
              }
            });
            this.set("validationErrors", validationErrors);
          } else {
            this.get("onResetPassword")().then(() => {
              debugger;
              // successful message
              this.set("serverError", null);
              this.set("successMessage", "true");
            }).catch(error => {
              this.set("successMessage", null);
              // server error
              if (error.errors.error !== undefined) {
                let errorObject = error.errors.error;
                let message = errorObject.detail;
                this.set("serverError", message);
              } else {
                // 500 error
                if (error.errors[0] !== undefined) {
                  let status = error.errors[0].status;
                  let title = error.errors[0].title;
                  let message = `${status} ${title}`;
                  this.set("serverError", message);
                }
              }
            });
          }
        });
      }
    }
  });
});