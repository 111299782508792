define("portal-user-onboarding/components/modal-complete", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    revealPassword: false,

    didInsertElement() {
      this._super(...arguments);
      $("body .modals").remove();
      $(".ui.tiny.modal").modal({
        duration: 120,
        closable: false,
        centered: false
      }).modal("show");

      $(".complete-reveal-button").transition("shake", "900ms");
    },

    actions: {
      complete() {
        this.get("onComplete")();
      },

      revealPassword() {
        this.set("revealPassword", true);
      }
    }
  });
});