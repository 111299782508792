define("portal-user-onboarding/routes/namespace/onboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        clientConfig: this.modelFor("namespace"),
        onboardingUser: this.store.peekAll("onboarding-user").get("firstObject"),
        onboardedUser: this.store.peekAll("onboarded-user").get("firstObject"),
        client: this.store.peekRecord("public-client", this.modelFor("namespace").get("clientId"))
      });
    },

    actions: {
      onboardUser() {
        let onboardedUser = this.modelFor(this.routeName).onboardedUser;

        let onboardingUser = this.modelFor(this.routeName).onboardingUser;
        let onboardingCode = onboardingUser.get("onboardingCode");

        return onboardedUser.save({ adapterOptions: { onboarding_code: onboardingCode } }).then(onboardedUser => {
          return this.transitionTo("namespace.complete");
        });
      }
    }
  });
});