define("portal-user-onboarding/components/modal-eula", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isLoading: true,
    termsOfUseText: "",

    didInsertElement() {
      this._super(...arguments);
      $("body .modals").remove();
      $(".ui.tiny.modal").modal({
        duration: 420,
        closable: false,
        transition: "fly up",
        centered: false
      }).modal("show");

      // fetch terms of use

      let url = this.get("model.clientConfig.urls.termsOfUseUrl");
      let _this = this;
      $.ajax({
        url: url,
        type: "GET",
        dataType: "text",
        success(result) {
          let termsOfUseText = result.split(/\n\n/g);
          _this.set("isLoading", false);
          _this.set("termsOfUseText", termsOfUseText);
        },
        error() {
          _this.set("error", "Could not load Terms of Use");
          _this.set("isLoading", false);
        }
      });
    },

    actions: {
      close() {
        this.set("model.onboardedUser.acceptTermsOfUse", true);
        $(".ui.tiny.modal").modal({
          duration: 30
        }).modal("hide");
      }
    }
  });
});