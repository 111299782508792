define("portal-user-onboarding/models/public-client-config", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    clientId: _emberData.default.attr("string"),
    theme: (0, _attributes.fragment)("public-client-config-theme"),
    assets: (0, _attributes.fragment)("public-client-config-asset"),
    urls: (0, _attributes.fragment)("public-client-config-url"),
    overrides: (0, _attributes.fragment)("public-client-config-overrides"),
    buildfire: (0, _attributes.fragment)("public-client-config-buildfire")
  });
});