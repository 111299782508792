define("portal-user-onboarding/router", ["exports", "portal-user-onboarding/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("namespace", { path: "/:namespace", resetNamespace: true }, function () {
      this.route("lookup", { path: "/lookup", resetNamespace: false });
      this.route("invite", { path: "/:invite_code", resetNamespace: false });
      this.route("reset-password", {
        path: "/reset-password/:reset_code",
        resetNamespace: false
      });
      this.route("onboard", {
        path: "/onboard/:onboarding_code",
        resetNamespace: false
      });
      this.route("eula", {
        path: "/eula",
        resetNamespace: false
      });
      this.route("electronic-records", {
        path: "/electronic-records",
        resetNamespace: false
      });
      this.route("complete", {
        path: "/complete",
        resetNamespace: false
      });
      this.route("final", {
        path: "/how-to-login",
        resetNamespace: false
      });
    });
  });

  exports.default = Router;
});