define("portal-user-onboarding/adapters/onboarding-user", ["exports", "portal-user-onboarding/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function () {
      return "onboarding";
    },

    urlForQueryRecord(query, modelName) {
      let url = this._super(...arguments);
      return url.replace(`onboarding`, `onboarding/lookup`);
    }
  });
});