define("portal-user-onboarding/models/public-client-config-theme", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    primaryColor: _emberData.default.attr("string"),
    secondaryColor: _emberData.default.attr("string"),
    tertiaryColor: _emberData.default.attr("string")
  });
});